@charset "UTF-8";
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.woff2) format("woff2"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.woff) format("woff"), url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 350;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.otf) format("opentype");
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.woff2) format("woff2"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.woff) format("woff"), url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.otf) format("opentype");
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Oswald/Oswald-Medium.ttf) format("truetype");
}

html {
  font-size: 62.5%;
  /* font-size 1em = 10px on default browser settings */
  height: 100%;
}

body {
  font-family: "NotoSansCJKjp", sans-serif;
  min-height: 100%;
}

header {
  position: relative;
}

header input[type="checkbox"] {
  display: none;
}

header input[type="checkbox"]:checked + label span {
  background-color: #000;
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
}

header input[type="checkbox"]:checked + label span:first-child {
  transform: translate(-50%, 0) rotate(45deg);
}

header input[type="checkbox"]:checked + label span:nth-child(2) {
  height: 0;
  transition: height 0s, width 0s;
  width: 0;
}

header input[type="checkbox"]:checked + label span:last-child {
  transform: translate(-50%, 0) rotate(-45deg);
}

@media all and (max-width: 750px) {
  header input[type="checkbox"]:checked ~ .global-menu-area {
    height: 100vh;
    z-index: 2;
  }
}

header input[type="checkbox"]:checked ~ .global-menu-area nav {
  right: 0;
}

header label {
  cursor: pointer;
  display: none;
}

@media all and (max-width: 750px) {
  header label {
    background-color: #b8b8b8;
    display: block;
    height: 70px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    width: 70px;
  }
}

header label span {
  background: #fff;
  display: block;
  height: 2px;
  left: 25%;
  position: absolute;
  transition: 0.5s;
  width: 50%;
}

header label span:first-child {
  top: calc(50% - 7.5px);
}

header label span:nth-child(2) {
  top: calc(50% - .5px);
}

header label span:last-child {
  top: calc(50% + 6.5px);
}

header .logo-contact-area {
  display: flex;
  overflow: hidden;
  /* IE11 ではなぜか右に空きができる。それを防ぐ値 */
  width: 100%;
}

@media all and (max-width: 750px) {
  header .logo-contact-area {
    height: 70px;
    padding-right: 70px;
  }
}

header .logo-contact-area a {
  align-items: center;
  color: #000000;
  display: flex;
  justify-content: center;
  position: relative;
}

header .logo-contact-area a span {
  text-align: center;
  width: 100%;
}

header .logo-contact-area a.logo {
  flex-grow: 2;
  justify-content: flex-start;
  padding: 2rem;
}

@media all and (max-width: 750px) {
  header .logo-contact-area a.logo {
    padding: 2rem 0;
  }
}

header .logo-contact-area a.tel {
  font-family: "Oswald", "NotoSansCJKjp", sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  letter-spacing: .1em;
  line-height: .5;
  padding: 0 1em;
  vertical-align: middle;
}

@media all and (max-width: 750px) {
  header .logo-contact-area a.tel {
    font-size: 2rem;
  }
}

header .logo-contact-area a.tel span::before {
  content: url(../images/common/icon_tel.png);
  display: inline-block;
  margin: 0 .25em .25em 0;
  vertical-align: middle;
}

header .logo-contact-area a.contact {
  background-color: #000;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: .1em;
  line-height: 1;
  padding: 0 1em;
  vertical-align: middle;
}

header .logo-contact-area a.contact span {
  line-height: 1.2;
}

header .logo-contact-area a.contact span::before {
  content: url(../images/common/icon_contact.png);
  display: inline-block;
  margin: 0 .25em 0 0;
  vertical-align: middle;
}

header .logo-contact-area a.EN {
  background-color: #4c4c4c;
  color: #fff;
  font-family: "Oswald", "NotoSansCJKjp", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: .1em;
  line-height: 1.5;
  text-align: center;
  width: 7em;
}

@media all and (max-width: 750px) {
  header .logo-contact-area a.EN {
    flex-grow: 2;
  }
}

header .logo-contact-area a.EN span::before {
  content: url(../images/common/icon_language.png);
  display: block;
}

header .global-menu-area {
  background-color: #d1d1d1;
  font-family: "NotoSansCJKjp", sans-serif;
  padding: 0 5vw;
  text-align: center;
}

@media all and (max-width: 750px) {
  header .global-menu-area {
    background-color: transparent;
    height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 70px;
    transition: 0.5s;
    width: 100vw;
  }
}

header .global-menu-area nav {
  background-color: #d1d1d1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media all and (max-width: 750px) {
  header .global-menu-area nav {
    position: absolute;
    right: -100%;
    top: 0;
    transition: left 0.5s, right 0.5s, transform 0.5s;
    width: 100%;
  }
}

header .global-menu-area nav a {
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  color: #000000;
  display: block;
  font-size: 1.5rem;
  line-height: 1;
  padding: 1.5em 0;
  text-decoration: none;
  transition: border 0.5s;
}

@media all and (max-width: 750px) {
  header .global-menu-area nav a {
    border-bottom: 1px dotted #fff;
    font-size: 2rem;
    width: 100%;
  }
  header .global-menu-area nav a:last-child {
    border-bottom: none;
  }
}

header .global-menu-area nav a:hover, header .global-menu-area nav a:active {
  border-bottom: 3px solid #000;
}

@media all and (max-width: 750px) {
  header .global-menu-area nav a:hover, header .global-menu-area nav a:active {
    background-color: #000;
    color: #fff;
  }
}

header .global-menu-area nav a.active {
  border-bottom: 3px solid #000;
}

@media all and (max-width: 750px) {
  header .global-menu-area nav a.active {
    background-color: #000;
    border-bottom: inherit;
    color: #fff;
  }
}

main {
  display: block;
}

section {
  display: block;
}

article {
  padding: 4rem 0;
}

footer {
  border-top: 1px solid #b4b4b4;
  display: block;
}

footer .footer-contents-area {
  display: flex;
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.3rem;
  font-weight: 350;
  letter-spacing: .1em;
  padding-bottom: 2rem;
  padding-top: 2rem;
  position: relative;
}

footer .footer-contents-area nav {
  display: flex;
  flex-grow: 2;
}

footer .footer-contents-area nav a {
  border-left: 1px solid #b4b4b4;
  border-right: 1px solid #b4b4b4;
  color: #000000;
  display: block;
  margin: 0 0 0 -1px;
  padding: 0 2em;
}

footer .footer-contents-area small {
  display: block;
  font-size: 1.3rem;
  padding: 0 0 0 2em;
}

.title-area {
  align-items: center;
  background: linear-gradient(90deg, #363636, #363636 55%, transparent 65%, transparent);
  display: flex;
  height: 20vw;
  justify-content: flex-start;
  position: relative;
}

@media all and (max-width: 750px) {
  .title-area {
    height: 30vw;
  }
}

.title-area::after {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 45%;
  z-index: -1;
}

.title-area.top {
  background: url(../images/top/main.jpg) no-repeat 0 0;
  background-size: 100%;
  height: calc(100vw * .4);
}

.title-area.top::after {
  display: none;
}

.title-area.raw-materials::after {
  background: url(../images/raw-materials/main.jpg) no-repeat right 20%;
  background-size: 100%;
}

.title-area.new-materials::after {
  background: url(../images/new-materials/main.jpg) no-repeat right 20%;
  background-size: 100%;
}

.title-area.facilities::after {
  background: url(../images/facilities/main.jpg) no-repeat right 20%;
  background-size: 100%;
}

.title-area.quality-control::after {
  background: url(../images/quality-control/main.jpg) no-repeat right 40%;
  background-size: 100%;
}

@media all and (max-width: 750px) {
  .title-area.quality-control::after {
    background-size: 140%;
    background-position: right 20%;
  }
}

.title-area.about::after {
  background: url(../images/about/main.jpg) no-repeat right 40%;
  background-size: 100%;
}

@media all and (max-width: 750px) {
  .title-area.about::after {
    background-size: 140%;
    background-position: right 20%;
  }
}

h1 {
  color: #fff;
  font-family: "NotoSerifCJKjp", serif;
  font-size: 5rem;
  font-weight: 300;
  letter-spacing: 0.22em;
  margin-bottom: 0;
  text-shadow: 5px 5px 8px #000;
}

h1.top {
  display: inline-block;
  font-weight: 500;
  line-height: .5;
}

@media all and (max-width: 750px) {
  h1.top {
    font-size: 5vw;
  }
}

h1.top::after {
  background: url(../images/top/logo.png) no-repeat center center;
  background-size: 50%;
  content: '';
  display: block;
  height: calc((5em * 1.22 + 1em) / 2);
  margin: 1em 0 0;
  text-align: center;
  width: calc(5em * 1.22 + 1em);
}

h1.top span {
  font-family: "NotoSerifCJKjp", serif;
  font-size: 50%;
}

h1 img {
  width: 100%;
}

h1 span {
  display: block;
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 40%;
  letter-spacing: .08em;
  margin: 1em 0 0;
}

h2 {
  font-family: "NotoSerifCJKjp", serif;
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.022em;
  line-height: 1.5;
  margin-bottom: 1em;
}

h2 + p {
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.4rem;
  font-weight: 350;
  letter-spacing: 0.22em;
  line-height: 1.5;
  margin: 0 0 4em;
}

h3 + p, h4 + p, h4 + .flex-with-photo-area p, h4 + ul li, h5 + p {
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.4rem;
  font-weight: 350;
  letter-spacing: 0.12em;
  line-height: 1.5;
  margin: 0;
  text-align: justify;
}

h3 {
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.8rem;
  font-weight: 350;
  letter-spacing: 0.12em;
  line-height: 1.5;
  margin: 3em 0 1em;
}

h3::after {
  border-bottom: 1px solid #b4b4b4;
  border-top: 1px solid #b4b4b4;
  content: '';
  display: block;
  height: 3px;
  margin: .5em 0 0;
  width: 100%;
}

h4 {
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.12em;
  line-height: 1.3;
  margin: 3em 0 1em;
  padding: 0 0 0 1em;
  text-indent: -1.125em;
}

h4::before {
  content: '■';
  margin: 0 .125em 0 0;
}

h4::after {
  border-bottom: 1px solid #b4b4b4;
  border-top: 1px solid #b4b4b4;
  content: '';
  display: block;
  height: 3px;
  left: -1.125em;
  margin: .5em 0 0;
  position: relative;
  width: calc(100% + 1.125em);
}

h4 span {
  color: #d70c19;
  margin: 0 0 0 1em;
}

h4 + .flex-with-photo-area {
  display: flex;
}

h4 + .flex-with-photo-area figure {
  margin: 0 4rem 0 0;
  min-width: 190px;
}

h4 + .flex-with-photo-area figure img {
  width: 100%;
}

h4 + ul {
  padding-left: 2em;
}

h4 + ul li {
  margin-bottom: 1em;
}

h5 {
  color: #ac6c04;
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.12em;
  line-height: 1.3;
  margin: 1em 0;
}

h5::before {
  content: '■';
  margin: 0 .125em 0 0;
}

h5::after {
  border-bottom: 1px solid #e5d4d4;
  border-top: 1px solid #e5d4d4;
  content: '';
  display: block;
  height: 3px;
  margin: .5em 0 0;
  width: 100%;
}

h5 + p {
  margin-bottom: .5em;
}

figure {
  display: block;
  margin: 0;
}

figure img {
  max-width: 100%;
}

figure.raw-materials {
  background-color: #e1e1e1;
  display: flex;
  justify-content: space-between;
  margin: 3rem 0 8rem;
  padding: 3rem;
}

figure.raw-materials dl {
  flex-basis: calc((100% - 3rem * 2) / 2);
  position: relative;
}

figure.raw-materials dl::after {
  background-color: #363636;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  right: -3rem;
  top: 0;
  width: 1px;
}

figure.raw-materials dl:last-child::after {
  width: 0;
}

figure.raw-materials dl dt {
  background-color: #363636;
  color: #fff;
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.8rem;
  font-weight: 350;
  letter-spacing: .08em;
  line-height: 1.2;
  margin: 0 0 1.5em;
  padding: .25em 0;
  text-align: center;
}

figure.raw-materials dl dd {
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.5rem;
  font-weight: 350;
  letter-spacing: .08em;
  text-align: justify;
}

figure.raw-materials dl dd figure {
  margin: 2em 0 0;
  text-align: center;
}

figure.raw-materials dl dd figure img {
  min-width: 50%;
  width: 100%;
}

figure.raw-materials dl dd figure figcaption {
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: .05em;
  line-height: 1.5;
  margin: 2em 0 0;
  padding: 0;
  text-align: justify;
}

figure.new-materials img {
  max-width: 100%;
}

table {
  border: 1px solid #b4b4b4;
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
}

table th, table td {
  background-color: #ebebeb;
  border: 1px solid #b4b4b4;
  padding: .25em .5em;
  vertical-align: top;
}

table.history td:nth-last-child(2) {
  text-align: right;
}

.local-menu-area {
  border-bottom: 1px solid #b4b4b4;
}

.local-menu-area nav {
  display: flex;
}

.local-menu-area nav a {
  color: #000000;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #fff;
  display: block;
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: .1em;
  margin: 0 3em 0 0;
  padding: 1em 0;
  text-decoration: none;
  transition: 0.5s;
}

.local-menu-area nav a::before {
  content: '▶︎';
  margin: 0 .5em 0 0;
}

.local-menu-area nav a:last-child {
  margin-right: 0;
}

.local-menu-area nav a:hover {
  border-bottom: 3px solid #d70c19;
}

.arrow {
  color: #000000;
  display: inline-block;
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.4rem;
  font-weight: 350;
  letter-spacing: .1em;
  margin: 0 2em 0 0;
}

.arrow::after {
  display: inline-block;
  margin: .25em 0 0 .5em;
  vertical-align: middle;
}

.arrow.rd::after {
  content: url("../images/common/icon_arrow_rd.png");
}

.arrow.bk::after {
  content: url("../images/common/icon_arrow_bk.png");
}

.contents-width {
  margin: 0 5vw;
  width: 90vw;
}

.go-to-top-area {
  position: relative;
  height: 30px;
}

@media all and (max-width: 750px) {
  .go-to-top-area {
    height: 70px;
  }
}

.go-to-top-area > a {
  background-color: #000;
  display: block;
  height: 30px;
  position: absolute;
  right: 0;
  width: calc((100% - (1% * 4))/5);
  top: 0;
}

@media all and (max-width: 750px) {
  .go-to-top-area > a {
    height: 70px;
    width: 70px;
  }
}

.go-to-top-area > a::after {
  content: url(../images/common/icon_arrow_wh_up.png);
  display: block;
  height: 10px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  z-index: 1;
}

.top-contents-area .lead-area {
  display: flex;
  padding: 5vw 0;
}

.top-contents-area .lead-area h2 {
  font-family: "NotoSerifCJKjp", serif;
  font-size: 4rem;
  font-weight: 300;
  letter-spacing: .1em;
  line-height: 1;
  margin: 0 0 .5em;
}

@media all and (max-width: 750px) {
  .top-contents-area .lead-area h2 {
    font-size: 3rem;
  }
}

.top-contents-area .lead-area h2 + p {
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.3;
  margin: 0 0 1em;
}

.top-contents-area .lead-area p {
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.4rem;
  font-weight: 350;
  letter-spacing: .05em;
  line-height: 2;
  text-align: justify;
}

.top-contents-area .lead-area div:first-child {
  margin: 0 2.5% 0 0;
  width: 45%;
}

.top-contents-area .lead-area div:first-child img {
  width: 100%;
}

.top-contents-area .lead-area div:last-child {
  width: 50%;
}

.top-contents-area .business-area {
  display: flex;
}

.top-contents-area .business-area > div {
  background-color: #ebebeb;
  border-right: 1px solid #fff;
  width: calc(100% / 3);
}

.top-contents-area .business-area > div:last-child {
  border-right: none;
}

.top-contents-area .business-area > div figure img {
  width: 100%;
}

.top-contents-area .business-area > div figure + div {
  padding: 2rem;
}

.top-contents-area .business-area > div figure + div h3 {
  font-family: "NotoSerifCJKjp", serif;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: .08em;
  margin: 0 0 1em;
  padding: 0;
}

.top-contents-area .business-area > div figure + div h3::after {
  display: none;
}

.top-contents-area .business-area > div figure + div h3 + p {
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: .08em;
  line-height: 1.5;
  margin: 0 0 1em;
}

.top-contents-area .business-area > div figure + div h3 ~ p:last-child {
  margin-bottom: 0;
}

.top-contents-area .topics-area ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 5vw 0;
}

@media all and (max-width: 750px) {
  .top-contents-area .topics-area ul {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.top-contents-area .topics-area ul li {
  border: 2px dashed #dadada;
  padding: .5vw;
  position: relative;
  width: calc((90vw - (1vw * 4)) / 5);
}

@media all and (max-width: 750px) {
  .top-contents-area .topics-area ul li {
    height: calc((90vw - 4vw) / 3);
    margin: 0 2vw 2vw 0;
    padding: 1vw;
    width: calc((90vw - 4vw) / 3);
  }
  .top-contents-area .topics-area ul li:nth-child(3) {
    margin-right: 0;
  }
}

.top-contents-area .topics-area ul li:first-child {
  align-items: center;
  display: flex;
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.8rem;
  justify-content: center;
  letter-spacing: .1em;
}

.top-contents-area .topics-area ul li:first-child span {
  font-weight: 900;
}

.top-contents-area .topics-area ul li:first-child span::after {
  margin-top: 0;
}

.top-contents-area .topics-area ul li a {
  color: #000000;
  text-decoration: none;
}

.top-contents-area .topics-area ul li figure {
  align-items: center;
  display: flex;
  height: 50%;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.top-contents-area .topics-area ul li figure img {
  max-height: 100%;
  max-width: 100%;
}

.top-contents-area .topics-area ul li figure + div {
  padding: .5vw;
}

@media all and (max-width: 750px) {
  .top-contents-area .topics-area ul li figure + div {
    padding: .5vw 0;
  }
}

.top-contents-area .topics-area ul li figure + div p {
  font-family: "NotoSansCJKjp", sans-serif;
  font-feature-settings: "palt";
  font-size: 1vw;
  font-weight: 350;
  letter-spacing: .05em;
  line-height: 1.5;
  margin: 0;
  text-align: justify;
}

@media all and (max-width: 750px) {
  .top-contents-area .topics-area ul li figure + div p {
    font-size: 1.75vw;
  }
}

.top-contents-area .topics-area ul li figure + div p:first-child {
  color: #d70c19;
  font-family: "Oswald", "NotoSansCJKjp", sans-serif;
  font-size: 1vw;
  font-weight: 500;
}

@media all and (max-width: 750px) {
  .top-contents-area .topics-area ul li figure + div p:first-child {
    font-size: 1.75vw;
  }
}

.facilities-others-area {
  display: flex;
  margin: 3rem 0 0;
}

.facilities-others-area > table {
  margin: 0 0 2rem 0;
  width: 18em;
}

.facilities-others-area > div {
  display: flex;
  flex-wrap: wrap;
  width: calc(90vw - 20em);
}

.facilities-others-area > div figure {
  margin: 0 0 2rem 2rem;
}

.about-summary-area {
  display: flex;
  justify-content: space-between;
}

.about-summary-area > table {
  margin: 0 0 2rem 0;
  width: 40vw;
}

.about-summary-area > div {
  width: 45vw;
}

.about-summary-area figure {
  width: 100%;
}

.about-summary-area figure img {
  width: 100%;
}

.about-summary-area figure + p {
  font-family: "NotoSansCJKjp", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 2rem;
}

.about-summary-area iframe {
  border: none;
  height: 45vw;
  width: 45vw;
}

.color-red {
  color: #d70c19;
}

.signature {
  display: block;
  margin: 1em 0 0 auto;
}
