@charset "UTF-8";

$breakpoints: (
  'small': 'all and (max-width: 750px)',
);
$color-text: #000000;
$color-text-red: #d70c19;
$color-text-brown: #ac6c04;
$color-border: rgb(180, 180, 180);
$color-border-red: $color-text-red;
$color-border-brown: rgb(229,212,212);
$color-header-menu-bg: #d1d1d1;
$contents-width: 90vw;
$duration: .5s;
$font-serif: 'NotoSerifCJKjp', serif;
$font-sans: 'NotoSansCJKjp', sans-serif;
$font-oswald: 'Oswald', 'NotoSansCJKjp', sans-serif;
$offset-basic: 5vw;

@mixin mq($breakpoint: small) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 200;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 300;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 400;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 500;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 600;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 700;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSerifCJKjp';
  font-style: normal;
  font-weight: 900;
  src:
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.woff2) format('woff2'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.woff) format('woff'),
    url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 100;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 300;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Light.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 350;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 400;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Regular.otf) format('opentype');;
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 500;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 700;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 900;
  src:
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.woff2) format('woff2'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.woff) format('woff'),
    url(../fonts/NotoSansCJKjp/NotoSansCJKjp-Black.otf) format('opentype');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src:
    url(../fonts/Oswald/Oswald-Medium.ttf) format('truetype');
}

html {
  font-size: 62.5%; /* font-size 1em = 10px on default browser settings */
  height: 100%;
}
body {
  font-family: $font-sans;
  min-height: 100%;
}
header {
  position: relative;
  $label-size: 70px;

  input[type="checkbox"] {
    display: none;
    &:checked {
      + label {
        // ×文字
        span {
          background-color: #000;
          display: block;
          height: 1px;
          left: 50%;
          position: absolute;
          top: 50%;
          &:first-child {
            transform: translate(-50%, 0) rotate(45deg);
          }
          &:nth-child(2) {
            height: 0;
            transition: height 0s, width 0s;
            width: 0;
          }
          &:last-child {
            transform: translate(-50%, 0) rotate(-45deg);
          }
        }
      }
      ~ .global-menu-area {
        @include mq() {
          height: 100vh;
          z-index: 2;
        }
        nav {
          right: 0;
        }
      }
    }
  }
  label {
    cursor: pointer;
    display: none;
    @include mq() {
      background-color: darken($color-header-menu-bg, 10%);
      display: block;
      height: $label-size;
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;
      width: $label-size;
    }
    span {
      background: #fff;
      display: block;
      height: 2px;
      left: 25%;
      position: absolute;
      transition: $duration;
      width: 50%;
      &:first-child {
        top: calc(50% - 7.5px);
      }
      &:nth-child(2) {
        top: calc(50% - .5px);
      }
      &:last-child {
        top: calc(50% + 6.5px);
      }
    }
  }
  .logo-contact-area {
    display: flex;
    overflow: hidden; /* IE11 ではなぜか右に空きができる。それを防ぐ値 */
    width: 100%;
    @include mq() {
      // flex-wrap: wrap;
      height: $label-size;
      padding-right: $label-size;
    }
    a {
      align-items: center;
      color: $color-text;
      display: flex;
      justify-content: center;
      position: relative;
      span {
        text-align: center;
        width: 100%;
      }
      &.logo {
        flex-grow: 2;
        justify-content: flex-start;
        padding: 2rem;
        @include mq() {
        //   flex-basis: 1;
          padding: 2rem 0;
        //   // width: 100vw;
        //   width: 100%;
        }
      }
      &.tel {
        font-family: $font-oswald;
        font-size: 2.2rem;
        font-weight: 500;
        letter-spacing: .1em;
        line-height: .5;
        padding: 0 1em;
        vertical-align: middle;
        @include mq() {
        //   flex-basis: calc( (100vw - #{$label-size}) * 0.6);
          font-size: 2rem;
        }
        span {
          &::before {
            content: url(../images/common/icon_tel.png);
            display: inline-block;
            margin: 0 .25em .25em 0;
            vertical-align: middle;
          }
        }
      }
      &.contact {
        background-color: #000;
        color: #fff;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: .1em;
        line-height: 1;
        padding: 0 1em;
        vertical-align: middle;
        @include mq() {
        //   flex-basis: calc( (100vw - #{$label-size}) * 0.4);
          // font-size: 1.3rem;
          // width: 8em;
        }
        span {
          line-height: 1.2;
          &::before {
            content: url(../images/common/icon_contact.png);
            display: inline-block;
            margin: 0 .25em 0 0;
            vertical-align: middle;
          }
        }
      }
      &.EN {
        background-color: #4c4c4c;
        color: #fff;
        font-family: $font-oswald;
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: .1em;
        line-height: 1.5;
        text-align: center;
        width: 7em;
        @include mq() {
          flex-grow: 2;
          // height: $label-size;
          // width: $label-size;
        }
        span {
          &::before {
            content: url(../images/common/icon_language.png);
            display: block;
          }
        }
      }
    }
  } // .logo-contact-area
  .global-menu-area {
    background-color: $color-header-menu-bg;
    font-family: $font-sans;
    padding: 0 $offset-basic;
    text-align: center;
    @include mq() {
      background-color: transparent;
      height: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: $label-size;
      transition: $duration;
      width: 100vw;
    }
    nav {
      background-color: $color-header-menu-bg;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      @include mq() {
        position: absolute;
        right: -100%;
        top: 0;
        transition: left $duration, right $duration, transform $duration;
        // width: 100vw;
        width: 100%;
      }
      a {
        border-bottom: 3px solid transparent;
        border-top: 3px solid transparent;
        color: $color-text;
        display: block;
        font-size: 1.5rem;
        line-height: 1;
        padding: 1.5em 0;
        text-decoration: none;
        transition: border $duration;
        @include mq() {
          border-bottom: 1px dotted #fff;
          font-size: 2rem;
          width: 100%;
          &:last-child {
            border-bottom: none;
          }
        }
        &:hover,&:active {
          border-bottom: 3px solid #000;
          @include mq() {
            background-color: #000;
            color: #fff;
          }
        }
        &.active {
          border-bottom: 3px solid #000;
          @include mq() {
            background-color: #000;
            border-bottom: inherit;
            color: #fff;
          }
        }
      }
    }
  }
} // header
main {
  display: block;
}
section {
  display: block;
}
article {
  padding: 4rem 0;
}
footer {
  border-top: 1px solid $color-border;
  display: block;
  .footer-contents-area {
    display: flex;
    // flex-wrap: wrap;
    font-family: $font-sans;
    font-size: 1.3rem;
    font-weight: 350;
    letter-spacing: .1em;
    padding-bottom: 2rem;
    padding-top: 2rem;
    position: relative;
    // @include mq() {
    //   flex-direction: column;
    // }
    nav {
      display: flex;
      flex-grow: 2;
      // @include mq() {
      //   justify-content: center;
      //   padding: 1em 0;
      //   text-align: center;
      // }
      a {
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
        color: $color-text;
        display: block;
        margin: 0 0 0 -1px;
        padding: 0 2em;
        // @include mq() {
        //   padding: 0 1em;
        // }
      }
    }
    small {
      display: block;
      font-size: 1.3rem;
      padding: 0 0 0 2em;
      // @include mq() {
      //   font-size: 1rem;
      //   padding: 0;
      //   text-align: center;
      // }
    }
  }
}
.title-area {
  align-items: center;
  background: linear-gradient(90deg, #363636, #363636 55%, transparent 65%, transparent);
  display: flex;
  height: 20vw;
  justify-content: flex-start;
  position: relative;
  @include mq () {
    height: 30vw;
  //   // width: 100vw;
  //   width: 100%;
  }
  &::after {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 45%;
    z-index: -1;
  }
  &.top {
    background: url(../images/top/main.jpg) no-repeat 0 0;
    background-size: 100%;
    height: calc(100vw * .4);
    &::after {
      display: none;
    }
    // @include mq() {
    //   height: calc( 100vw * .5);
    // }
  }
  &.raw-materials::after {
    background: url(../images/raw-materials/main.jpg) no-repeat right 20%;
    background-size: 100%;
    // @include mq() {
    //   background-size: 200%;
    //   background-position: 20% 20%;
    // }
  }
  &.new-materials::after {
    background: url(../images/new-materials/main.jpg) no-repeat right 20%;
    background-size: 100%;
    // @include mq() {
    //   background-size: 200%;
    //   background-position: 40% 20%;
    // }
  }
  &.facilities::after {
    background: url(../images/facilities/main.jpg) no-repeat right 20%;
    background-size: 100%;
    // @include mq() {
    //   background-size: 200%;
    //   background-position: 20% 20%;
    // }
  }
  &.quality-control::after {
    background: url(../images/quality-control/main.jpg) no-repeat right 40%;
    background-size: 100%;
    @include mq() {
      background-size: 140%;
      background-position: right 20%;
    }
  }
  &.about::after {
    background: url(../images/about/main.jpg) no-repeat right 40%;
    background-size: 100%;
    @include mq() {
      background-size: 140%;
      background-position: right 20%;
    }
  }
}
h1 {
  color: #fff;
  font-family: $font-serif;
  font-size: 5rem;
  // font-size: 4vw;
  font-weight: 300;
  letter-spacing: 0.22em;
  margin-bottom: 0;
  text-shadow: 5px 5px 8px #000;
  // @include mq() {
  //   font-size: 3rem;
  // }
  &.top {
    display: inline-block;
    // font-size: 4vw;
    font-weight: 500;
    line-height: .5;
    @include mq() {
      font-size: 5vw;
    }
    &::after {
      background: url(../images/top/logo.png) no-repeat center center;
      background-size: 50%;
      content: '';
      display: block;
      height: calc((5em * 1.22 + 1em) / 2);
      margin: 1em 0 0;
      text-align: center;
      width: calc(5em * 1.22 + 1em);
    }
    span {
      font-family: $font-serif;
      font-size: 50%;
    }
  }
  img {
    width: 100%;
  }
  span {
    display: block;
    font-family: $font-sans;
    font-size: 40%;
    letter-spacing: .08em;
    margin: 1em 0 0;
  }
}
h2 {
  font-family: $font-serif;
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.022em;
  line-height: 1.5;
  margin-bottom: 1em;
  + p {
    font-family: $font-sans;
    font-size: 1.4rem;
    font-weight: 350;
    letter-spacing: 0.22em;
    line-height: 1.5;
    margin: 0 0 4em;
  }
}
%text-positioned-after-header {
  font-family: $font-sans;
  font-size: 1.4rem;
  font-weight: 350;
  letter-spacing: 0.12em;
  line-height: 1.5;
  margin: 0;
  text-align: justify;
}
h3 {
  font-family: $font-sans;
  font-size: 1.8rem;
  font-weight: 350;
  letter-spacing: 0.12em;
  line-height: 1.5;
  margin: 3em 0 1em;
  &::after {
    border-bottom: 1px solid $color-border;
    border-top: 1px solid $color-border;
    content: '';
    display: block;
    height: 3px;
    margin: .5em 0 0;
    width: 100%;
  }
  + p {
    @extend %text-positioned-after-header;
  }
}
h4 {
  font-family: $font-sans;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.12em;
  line-height: 1.3;
  margin: 3em 0 1em;
  padding: 0 0 0 1em;
  text-indent: -1.125em;
  &::before {
    content: '■';
    margin: 0 .125em 0 0;
  }
  &::after {
    border-bottom: 1px solid $color-border;
    border-top: 1px solid $color-border;
    content: '';
    display: block;
    height: 3px;
    left: -1.125em;
    margin: .5em 0 0;
    position: relative;
    width: calc(100% + 1.125em);
  }
  span {
    color: $color-text-red;
    margin: 0 0 0 1em;
  }
  + p {
    @extend %text-positioned-after-header;
  }
  + .flex-with-photo-area {
    display: flex;
    // @include mq() {
    //   flex-direction: column;
    // }
    figure {
      margin: 0 4rem 0 0;
      min-width: 190px;
      // @include mq() {
      //   margin: 0 auto 2rem;
      //   min-width: auto;
      //   width: 60%;
      // }
      img {
        width: 100%;
      }
    }
    p {
      @extend %text-positioned-after-header;
    }
  }
  + ul {
    padding-left: 2em;
    li {
      @extend %text-positioned-after-header;
      margin-bottom: 1em;
    }
  }
}
h5 {
  color: $color-text-brown;
  font-family: $font-sans;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.12em;
  line-height: 1.3;
  margin: 1em 0;
  &::before {
    content: '■';
    margin: 0 .125em 0 0;
  }
  &::after {
    border-bottom: 1px solid $color-border-brown;
    border-top: 1px solid $color-border-brown;
    content: '';
    display: block;
    height: 3px;
    margin: .5em 0 0;
    width: 100%;
  }
  + p {
    @extend %text-positioned-after-header;
    margin-bottom: .5em;
  }
}
figure {
  display: block;
  margin: 0;
  img {
    max-width: 100%;
  }
  &.raw-materials {
    background-color: rgb(225,225,225);
    display: flex;
    justify-content: space-between;
    margin: 3rem 0 8rem;
    padding: 3rem;
    // @include mq() {
    //   flex-direction: column;
    // }
    dl {
      flex-basis: calc((100% - 3rem * 2) / 2);
      position: relative;
      &::after {
        background-color: #363636;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: -3rem;
        top: 0;
        width: 1px;
        // @include mq() {
        //   bottom: -3rem;
        //   height: 1px;
        //   left: 0;
        //   right: auto;
        //   top: auto;
        //   width: 100%;
        // }
      }
      &:last-child {
        // @include mq() {
        //   margin-top: 6rem;
        // }
        &::after {
          width: 0;
        }
      }
      dt {
        background-color: #363636;
        color: #fff;
        font-family: $font-sans;
        font-size: 1.8rem;
        font-weight: 350;
        letter-spacing: .08em;
        line-height: 1.2;
        margin: 0 0 1.5em;
        padding: .25em 0;
        text-align: center;
      }
      dd {
        font-family: $font-sans;
        font-size: 1.5rem;
        font-weight: 350;
        letter-spacing: .08em;
        text-align: justify;
        figure {
          margin: 2em 0 0;
          text-align: center;
          img {
            min-width: 50%;
            width: 100%;
          }
          figcaption {
            font-family: $font-sans;
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: .05em;
            line-height: 1.5;
            margin: 2em 0 0;
            padding: 0;
            text-align: justify;
          }
        }
      }
    }
  } // &.raw-materials
  &.new-materials {
    img {
      max-width: 100%;
    }
  }
} // figure
table {
  border: 1px solid $color-border;
  font-family: $font-sans;
  font-size: 1.4rem;
  font-weight: 400;
  th, td {
    background-color: #ebebeb;;
    border: 1px solid $color-border;
    padding: .25em .5em;
    vertical-align: top;
  }
  &.history {
    td {
      &:first-child {

      }
      &:nth-last-child(2) {
        text-align: right;
      }
    }
  }
} // table
.local-menu-area {
  border-bottom: 1px solid $color-border;
  nav {
    display: flex;
    // @include mq() {
    //   flex-direction: column;
    //   padding: 1rem 0;
    // }
    a {
      color: $color-text;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #fff;
      display: block;
      font-family: $font-sans;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: .1em;
      margin: 0 3em 0 0;
      padding: 1em 0;
      text-decoration: none;
      transition: $duration;
      // @include mq() {
      //   padding: .5em 0;
      // }
      &::before {
        content: '▶︎';
        margin: 0 .5em 0 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        border-bottom: 3px solid $color-border-red;
      }
    }
  }
}
.arrow {
  color: $color-text;
  display: inline-block;
  font-family: $font-sans;
  font-size: 1.4rem;
  font-weight: 350;
  letter-spacing: .1em;
  margin: 0 2em 0 0;
  &::after {
    display: inline-block;
    margin: .25em 0 0 .5em;
    vertical-align: middle;
  }
  &.rd {
    &::after {
      content: url('../images/common/icon_arrow_rd.png');
    }
  }
  &.bk {
    &::after {
      content: url('../images/common/icon_arrow_bk.png');
    }
  }
}
.contents-width {
  margin: 0 $offset-basic;
  width: $contents-width;
}
.go-to-top-area {
  position: relative;
  height: 30px;
  @include mq() {
    height: 70px;
  }
  > a {
    background-color: #000;
    display: block;
    height: 30px;
    position: absolute;
    right: 0;
    width: calc((100% - (1% * 4))/5);
    top: 0;
    @include mq() {
      height: 70px;
      width: 70px;
    }
    &::after {
      content: url(../images/common/icon_arrow_wh_up.png);
      display: block;
      height: 10px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      z-index: 1;
    }
  }
}
.top-contents-area {
  .lead-area {
    display: flex;
    padding: $offset-basic 0;
    // @include mq() {
    //   flex-direction: column;
    // }
    h2 {
      font-family: $font-serif;
      font-size: 4rem;
      font-weight: 300;
      letter-spacing: .1em;
      line-height: 1;
      margin: 0 0 .5em;
      @include mq() {
        font-size: 3rem;
      }
    }
    h2 + p {
      font-family: $font-sans;
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 1.3;
      margin: 0 0 1em;
    }
    p {
      font-family: $font-sans;
      font-size: 1.4rem;
      font-weight: 350;
      letter-spacing: .05em;
      line-height: 2;
      text-align: justify;
      // @include mq() {
      //   line-height: 1.6;
      // }
    }
    div:first-child {
      margin: 0 2.5% 0 0;
      width: 45%;
      // @include mq() {
      //   margin: 0 0 ($offset-basic / 2);
      //   width: 100%;
      // }
      img {
        width: 100%;
      }
    }
    div:last-child {
      width: 50%;
      // @include mq() {
      //   width: 100%;
      // }
    }
  } // .lead-area
  .business-area {
    display: flex;
    // @include mq() {
    //   flex-direction: column;
    // }
    > div {
      background-color: #ebebeb;
      border-right: 1px solid #fff;
      width: calc(100% / 3);
      // @include mq() {
      //   width: 100%;
      // }
      &:last-child {
        border-right: none;
      }
      figure {
        img {
          width: 100%;
        }
      }
      figure + div {
        padding: 2rem;
        h3 {
          font-family: $font-serif;
          font-size: 2.2rem;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: .08em;
          margin: 0 0 1em;
          padding: 0;
          &::after {
            display: none;
          }
        }
        h3 + p {
          font-family: $font-sans;
          font-size: 1.4rem;
          font-weight: 400;
          letter-spacing: .08em;
          line-height: 1.5;
          margin: 0 0 1em;
        }
        h3 ~ p:last-child {
          margin-bottom: 0;
        }
      }
    }
  } // .business-area
  .topics-area {
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      padding: $offset-basic 0;
      @include mq() {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      li {
        border: 2px dashed #dadada;
        // height: calc((#{$contents-width} - (1vw * 4)) / 5);
        // padding: .5rem;
        padding: .5vw;
        position: relative;
        width: calc((#{$contents-width} - (1vw * 4)) / 5);
        @include mq() {
          height: calc((90vw - 4vw) / 3);
          margin: 0 2vw 2vw 0;
          padding: 1vw;
          width: calc((90vw - 4vw) / 3);
          &:nth-child(3) {
            margin-right: 0;
          }
        }
        &:first-child {
          align-items: center;
          display: flex;
          font-family: $font-sans;
          font-size: 1.8rem;
          justify-content: center;
          letter-spacing: .1em;
          // @include mq() {
          //   height: calc(100vw - #{$offset-basic} * 2);
          // }
          span {
            font-weight: 900;
            &::after {
              margin-top: 0;
            }
          }
        }
        a {
          color: $color-text;
          text-decoration: none;
        }
        figure {
          align-items: center;
          display: flex;
          height: 50%;
          justify-content: center;
          margin: 0;
          overflow: hidden;
          text-align: center;
          vertical-align: middle;
          width: 100%;
          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
        figure + div {
          // padding: 1rem;
          padding: .5vw;
          @include mq() {
            padding: .5vw 0;
          }
          p {
            font-family: $font-sans;
            font-feature-settings : "palt";
            // font-size: 1.4rem;
            font-size: 1vw;
            font-weight: 350;
            letter-spacing: .05em;
            line-height: 1.5;
            margin: 0;
            text-align: justify;
            @include mq() {
              font-size: 1.75vw;
            }
            &:first-child {
              color: $color-text-red;
              font-family: $font-oswald;
              // font-size: 1.3rem;
              font-size: 1vw;
              font-weight: 500;
              // margin-bottom: 1em;
              @include mq() {
                font-size: 1.75vw;
              }
            }
          }
        }
      } // li
    } // ul
  } // .topic-area
} // .top-contents-area
.facilities-others-area {
  display: flex;
  margin: 3rem 0 0;
  // @include mq() {
  //   flex-direction: column;
  // }
  > table {
    margin: 0 0 2rem 0;
    width: 18em;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    width: calc(90vw - 20em);
    figure {
      margin: 0 0 2rem 2rem;
      // @include mq() {
      //   margin: 0 1rem 1rem 0;
      // }
    }
  }
}
.about-summary-area {
  display: flex;
  justify-content: space-between;
  // @include mq() {
  //   flex-direction: column;
  // }
  > table {
    margin: 0 0 2rem 0;
    width: 40vw;
    // @include mq() {
    //   width: 100%;
    // }
  }
  > div {
    width: 45vw;
    // @include mq() {
    //   width: 100%;
    // }
  }
  figure {
    width: 100%;
    img {
      width: 100%;
    }
  }
  figure + p {
    font-family: $font-sans;
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 2rem;
    // @include mq() {
    //   margin-top: $offset-basic;
    // }
  }
  iframe {
    border: none;
    height: 45vw;
    width: 45vw;
    // @include mq() {
    //   height: 100vw - ($offset-basic * 2);
    //   width: 100vw - ($offset-basic * 2);
    // }
  }
}
.color-red {
  color: $color-text-red;
}
.signature {
  display: block;
  margin: 1em 0 0 auto;
}